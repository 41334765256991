<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> Filtros </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="filters.search"
                clearable
                placeholder="Busca por empresa ou por categoria"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :loading="loadingCities"
                v-model="filters.CityId"
                :items="cities"
                label="Cidade:"
              >
              </v-select
            ></v-col>
            <v-col cols="12" md="4">
              <v-select
                :loading="loadingPlans"
                v-model="filters.PlanId"
                :items="plans"
                label="Plano:"
              >
              </v-select
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-select
                v-model="filters.AdCategoryId"
                :items="adCategoryOptions"
                :loading="loadingAdCategories"
                label="Categoria"
              ></v-select
            ></v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="filters.automaticBilling"
                :items="[
                  { text: 'Todos', value: null },
                  { text: 'Ativada', value: 1 },
                  { text: 'Desativada', value: 0 },
                ]"
                label="Cobrança automática"
              ></v-select
            ></v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="filters.authorized"
                :items="[
                  { text: 'Ambos', value: null },
                  { text: 'Sim', value: 0 },
                  { text: 'Não', value: 1 },
                ]"
                label="Bloqueados"
              ></v-select
            ></v-col>
          </v-row>

          <div class="text-right mt-4">
            <v-btn @click="clearFilters()" text class="mr-2"
              >Limpar filtros</v-btn
            >
            <v-btn color="primary" @click="loadAdvertsData()">Filtrar</v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card class="mt-3">
      <v-toolbar flat>
        <v-card-title>Administrar anúncios</v-card-title>

        <v-spacer></v-spacer>
        <v-btn small rounded color="success" @click="openInsertScreen">
          Cadastrar <v-icon right dark> mdi-plus </v-icon></v-btn
        >
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          show-expand
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.adEnabled`]="{ item }">
            <v-icon color="green" v-if="item.enabled">
              mdi-check-circle
            </v-icon>

            <v-icon v-else color="red"> mdi-alert-circle </v-icon>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row class="pt-2 pb-2">
                <v-col sm="12">
                  <v-btn
                    text
                    small
                    :title="
                      item.automaticBilling
                        ? 'Desativar cobrança automática'
                        : 'Ativar cobrança automática'
                    "
                    @click="
                      toggleAutomaticBillingFlag(
                        item.id,
                        !item.automaticBilling
                      )
                    "
                  >
                    <v-icon class="pr-1">
                      {{
                        item.automaticBilling
                          ? "mdi-cash-remove"
                          : "mdi-cash-check"
                      }}
                    </v-icon>
                    {{
                      item.automaticBilling
                        ? "Desativar cobrança automática"
                        : "Ativar cobrança automática"
                    }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2 pb-2">
                <v-col sm="12">
                  <v-btn
                    text
                    small
                    title="Configurar"
                    @click="openSettingsScreen(item.id)"
                  >
                    <v-icon class="pr-1"> mdi-cogs </v-icon> Configurar
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2 pb-2">
                <v-col sm="12">
                  <v-btn
                    text
                    small
                    :title="item.enabled ? 'Desabilitar' : 'Habilitar'"
                    @click="
                      item.enabled ? disableAd(item.id) : enableAd(item.id)
                    "
                  >
                    <v-icon class="pr-1">
                      {{
                        item.enabled
                          ? "mdi-power-plug-off"
                          : "mdi-power-plug"
                      }}
                    </v-icon>
                    {{
                      item.enabled
                        ? "Desabilitar"
                        : "Habilitar"
                    }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2 pb-2">
                <v-col sm="12">
                  <v-btn
                    text
                    small
                    :title="item.authorized ? 'Bloquear' : 'Desbloquear'"
                    @click="toggleAuthorizedFlag(item.id, !item.authorized)"
                  >
                    <v-icon class="pr-1">
                      {{ item.authorized ? "mdi-lock" : "mdi-lock-open" }}
                    </v-icon>
                    {{ item.authorized ? "Bloquear" : "Desbloquear" }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2 pb-2">
                <v-col sm="12">
                  <v-btn
                    text
                    small
                    title="Excluir"
                    @click="deleteAnnouncement(item.id)"
                  >
                    <v-icon class="pr-1"> mdi-delete </v-icon> Excluir
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="page"
        :length="numberOfPages"
        :total-visible="15"
        @input="loadAdvertsData"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  data() {
    return {
      resultsPerPage: 15,
      totalRows: 0,
      page: 1,
      loading: false,
      loadingCities: false,
      loadingAdCategories: false,
      loadingPlans: false,
      singleExpand: true,
      expanded: [],
      headers: [
        { text: "#ID", value: "id" },
        {
          text: "Cliente",
          value: "fantasyName",
        },
        {
          text: "Plano",
          value: "planName",
        },
        {
          text: "Categoria",
          value: "categoryName",
        },
        { text: "Cidade", value: "cityName", sortable: false },
        { text: "Data de expiração", value: "expirationDate" },
        { text: "Bloqueado", value: "adBlocked" },
        { text: "Habilitado", value: "adEnabled", sortable: false },
        { text: "Ações", value: "data-table-expand" },
      ],
      items: [],
      cities: [],
      adCategoryOptions: [],
      plans: [],
      filters: {
        search: null,
        CityId: 2,
        PlanId: null,
        AdCategoryId: null,
        automaticBilling: null,
        authorized: null,
      },
    };
  },
  methods: {
    setAdsData(data) {
      const copyOfData = { ...data };

      this.totalRows = copyOfData.count;

      this.items = copyOfData.rows.map((item) => {
        item.planName = `${item.Plan.name}`;

        item.categoryName = item.AdCategory ? `${item.AdCategory.name}` : "";

        item.companyName = `${item.Company.companyName}`;

        item.fantasyName = `${item.Company.fantasyName}`;

        item.cityName = item.City ? `${item.City.name} - ${item.City.uf}` : "";

        item.adBlocked = item.authorized ? "Não" : "Sim";

        item.expirationDate = item.expirationDate
          ? this.$moment.utc(item.expirationDate).format("DD/MM/YYYY")
          : null;

        return item;
      });
    },
    async loadAdvertsData(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const response = await this.$axios.get(`/posts`, {
          params: {
            search: this.filters.search,
            CityId: this.filters.CityId,
            PlanId: this.filters.PlanId,
            AdCategoryId: this.filters.AdCategoryId,
            authorized: this.filters.authorized,
            automaticBilling: this.filters.automaticBilling,
            orderBy: 'plan',
            pagination: 1,
            resultsPerPage: this.resultsPerPage,
            page: currentPage,
          },
        });

        this.setAdsData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    deleteAnnouncementInArray(adId) {
      const index = this.items.findIndex((item) => item.id === adId);

      if (index !== -1) this.items.splice(index, 1);
    },
    async toggleAuthorizedFlag(adId, authorize) {
      try {
        const msg = authorize
          ? "Desbloquear este anúncio?"
          : "Bloquear este anúncio?";

        if (
          !(await this.$root.$confirm("Atenção", msg, {
            color: "primary",
          }))
        )
          return;

        this.loading = true;

        await this.$axios.put(`/posts/${adId}/lock-unlock`, {
          authorized: authorize,
        });

        this.loading = false;
        this.loadAdvertsData();
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    async toggleAutomaticBillingFlag(adId, value) {
      try {
        const msg = value
          ? "Ativar cobrança automática para este anúncio?"
          : "Desativar cobrança automática para este anúncio?";

        if (
          !(await this.$root.$confirm("Atenção", msg, {
            color: "primary",
          }))
        )
          return;

        this.loading = true;

        await this.$axios.put(`/posts/${adId}/automatic-billing`, {
          automaticBilling: value,
        });

        this.loading = false;
        this.loadAdvertsData();
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    async disableAd(adId) {
      try {
        this.loading = true;

        await this.$axios.put(`/posts/${adId}/disable`, {
          enabled: false,
        });

        this.loading = false;
        this.loadAdvertsData()
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    async enableAd(adId) {
      try {
        this.loading = true;

        await this.$axios.put(`/posts/${adId}/disable`, {
          enabled: true,
        });

        this.loading = false;
        this.loadAdvertsData()
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    async deleteAnnouncement(adId) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente excluir o anúncio selecionado? Essa ação não incorrerá em descontos nas cobranças já efetuadas.",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/posts/${adId}`);

        this.deleteAnnouncementInArray(adId);

        this.$toast.success("Dados deletados com sucesso");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);
        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loading = false;
      }
    },
    async getCities() {
      try {
        this.loadingCities = true;

        const res = await this.$axios.get("/cities");

        this.setCitiesData(res.data);

        this.loadingCities = false;
      } catch (error) {
        this.loadingCities = false;
        this.handleErrors(error);
      }
    },
    setCitiesData(cities) {
      this.cities = cities.map((c) => {
        return {
          value: c.id,
          text: `${c.name} - ${c.uf}`,
        };
      });
    },
    async getAdCategories() {
      try {
        this.loadingAdCategories = true;

        const res = await this.$axios.get("/posts/categories");

        this.setCategoriesData(res.data);

        this.loadingAdCategories = false;
      } catch (error) {
        this.loadingAdCategories = false;
        this.handleErrors(error);
      }
    },
    setCategoriesData(categories) {
      this.adCategoryOptions = categories.map((c) => {
        return {
          value: c.id,
          text: c.name,
        };
      });
    },
    async getPlans() {
      try {
        this.loadingPlans = true;

        const res = await this.$axios.get("/plans");

        this.setPlansData(res.data);

        this.loadingPlans = false;
      } catch (error) {
        this.loadingPlans = false;
        this.handleErrors(error);
      }
    },
    setPlansData(plans) {
      this.plans = plans.map((p) => {
        return {
          value: p.id,
          text: `${p.name} (R$ ${parseFloat(p.value)
            .toFixed(2)
            .replace(".", ",")}/mês)`,
        };
      });
    },
    clearFilters() {
      this.filters = {
        search: null,
        CityId: null,
        PlanId: null,
        AdCategoryId: null,
        automaticBilling: null,
        authorized: null,
      };

      this.loadAdvertsData();
    },
    openInsertScreen() {
      this.$router.push("/anuncio/cadastrar");
    },
    openSettingsScreen(announcementId) {
      this.$router.push(`/anuncio/${announcementId}/configurar`);
    },
    openCompleteScreen(announcementId) {
      this.$router.push(`/anuncio/${announcementId}/completar-cadastro`);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  mounted() {
    this.loadAdvertsData();
    this.getCities();
    this.getAdCategories();
    this.getPlans();
  },
};
</script>

<style></style>
